
// TODO: i18next breaks AOS animations, but not clue why.
// An AOS.refresh() at the right time could be the fix. but it is not clear

import React from 'react';
import { makeStyles } from "@material-ui/core";
import { graphql } from 'gatsby';

import Main from '../layouts/Main';
import WithLayout from '../layouts/WithChildrenLayout';
import {Section, SectionAlternate } from '../components/organisms';

import {MosaicHero,Process, OptionalFeatures, PartnerLogos, YoutubeFeature, CallToAction} from '../views';
import {HowItWorks,MetalChoice, BuiltOn} from "../content";

import {SEO} from "../components/seo";
import {images} from "../components/MediaLib"
import {Divider} from "../components/divider";


const useStyles = makeStyles(theme => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  sectionBuiltOn: {
    boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
    '& .section-alternate__content': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));


const IndexPage = (props) => {
  const classes = useStyles();
  // Translation temporarily disabled
  //const {t} = useTranslation();
  const t = (txt)=>txt;
  return (
    <WithLayout layout={Main}>
      <SEO title={t("Privazio")} />
      <MosaicHero
        title_lead={t("Run a Dedicated Private Cloud for your")}
        title_typed_ends={[
          t('startup'),
          t('team'),
          t('lab'),
          t('personal project'),
          t('small office'),
          t('home office'),
          t('and many more...')
        ]}
        subtitle={t("Privazio helps you deploy your private cloud like a pro.")}
        action={t("Get Started")}
        href={"/getstarted"}
        banner_image={images.hero_mosaic_light}
        banner_image_dk={images.hero_mosaic_dark}
      />
      <SectionAlternate className={classes.sectionBuiltOn}>
        <PartnerLogos
          title={t("Automation Toolkit")}
          subtitle={t("Privazio Automates your Apache Cloudstack Deployment with an inmutable toolkit based on Ansible and Docker")}
          partners={BuiltOn}
        />
      </SectionAlternate>
      <Divider/>
      <OptionalFeatures
        title_lead={t("Run on metal of")}
        title_end={t("your choice")}
        subtitle={t("Privazio helps deploy on affordable metal, no need to own a data center")}
        features={MetalChoice}
      />
      <Divider/>
      <SectionAlternate>
        <Process
          title={t("How it works")}
          subtitle={t("Privazio provides an automation toolkit that allows you to deploy from metal to service with confidence.")}
          action={t("Get Started")}
          href="/getstarted"
          process={HowItWorks}/>
      </SectionAlternate>
      <Section>
        <YoutubeFeature
          embed_url={"https://www.youtube.com/embed/vTPxBxhF7Ts?list=PLnIKk7GjgFlbG2W3lRW9Evjy2Vtg_HKHX"}
          title={t("European Cloudstack User Group")}
          subtitle={t("Presentation: from metal to service 100% automation with Apache Cloudstack and Ansible.")}
        />
      </Section>
      <SectionAlternate >
        <CallToAction
          href={"/getstarted"}
          action={t("Get Started")}
          title={t("Get started with Privazio")}
          subtitle={t("Start contributing and automating your cloud from metal to service.")}/>
      </SectionAlternate>
    </WithLayout>
  )
};

export default IndexPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["translation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;